import styled from '@emotion/styled'
import '@glidejs/glide/dist/css/glide.core.min.css'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  width: 100%;

  .glide__track {
    width: 100%;
    max-width: 100%;

    z-index: 3;
  }

  .glide__slide {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    max-width: 100%;
  }

  .glide__arrows {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .glide__arrow {
    position: absolute;
    top: calc(50% - 16px);

    width: 10px;
    height: 24px;
    padding: 1rem 0.75rem;

    border: none;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;

    outline: none;
    cursor: pointer;

    &--left {
      left: -12%;
    }

    &--right {
      transform: rotateY(180deg);
      right: -12%;
    }
  }

  .glide__bullets {
    position: absolute;
    bottom: 1rem;

    display: none;
    justify-content: center;

    width: 100%;
    max-width: 100%;
    margin: 1rem 0 0;

    z-index: 4;

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      display: flex;
    }
  }

  .glide__bullet {
    width: 8px;
    height: 8px;

    transition: all 0.225s ease-in;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-right: 1.5rem;

      @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
        margin-right: 1.333333333333333rem;
      }
    }

    &.glide__bullet__active {
      background: #fff;
    }

    &.glide__bullet__inactive {
      background: #7b7b7b;
    }
  }
`

export { Wrapper }
