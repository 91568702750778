import Link from 'next/link'

// Library
import Container from 'library/Container'
import LinkArrow from 'library/Icons/LinkArrow'

// Styles
import { ProjectsButton, Arrow } from 'styles/pages/home'

const SeeMore = ({ link, children, black }) => (
  <section>
    <Container>
      <Link href={link} passHref>
        <ProjectsButton black={black}>
          {children}
          <Arrow>
            <LinkArrow dark={black} />
          </Arrow>
        </ProjectsButton>
      </Link>
    </Container>
  </section>
)

SeeMore.defaultProps = {
  link: '/projetos'
}

export default SeeMore
