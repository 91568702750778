import styled from '@emotion/styled'

const Arrow = styled.div`
  display: inline-block;

  margin: 0;
  padding: 0;
`

const ProjectsButton = styled.a`
  display: block;

  max-width: 448px;
  width: 100%;
  margin: 4rem auto;
  padding: 0.875rem;

  background-color: ${({ theme, black }) =>
    black ? theme.colors.primaryDarkest : '#fff'};
  border: solid 1px
    ${({ black, theme }) => (black ? theme.colors.primaryDarkest : '#000')};
  color: ${({ black, theme }) =>
    black ? '#fff' : theme.colors.primaryDarkest};

  letter-spacing: 0.73px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  transition: all 0.225s ease-in;

  &:hover,
  &:disabled {
    background: ${({ black, theme }) =>
      black ? '#FFF' : theme.colors.primaryDarkest};
    color: ${({ black, theme }) =>
      black ? theme.colors.primaryDarkest : '#FFF'};
    border: solid 1px
      ${({ black, theme }) => (black ? '#000' : theme.colors.primaryDarkest)};

    font-weight: 400;
    ${Arrow} {
      filter: invert(1);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin: 6rem auto;
    padding: 0.75rem;

    font-size: 0.875rem;
  }
`

export { ProjectsButton, Arrow }
