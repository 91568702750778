import PropTypes from 'prop-types'

const LinkArrow = ({ width, height, dark }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {dark ? (
      <path d="M6 9h28l-8.615-8" stroke="#FFF" />
    ) : (
      <path d="M6 9h28l-8.615-8" stroke="#2B2B2B" />
    )}
  </svg>
)

LinkArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  dark: PropTypes.bool
}

LinkArrow.defaultProps = {
  width: '36',
  height: '10',
  dark: false
}

export default LinkArrow
