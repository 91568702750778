import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Glide from '@glidejs/glide'

// Styles
import { Wrapper } from './style'

const Carousel = ({
  element,
  hasDots,
  hasArrows,
  centerBullets,
  children,
  options
}) => {
  const [slider] = useState(new Glide(`.${element}`, options))
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    slider.mount()

    slider.on('run', event => {
      setCurrentIndex(slider.index)
    })

    return () => slider.destroy()
  }, [])

  return (
    <Wrapper centerBullets={centerBullets} className={element}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {React.Children.map(children, (slide, index) => {
            return (
              <li key={index}>
                {React.cloneElement(slide, {
                  className: slide.props.className
                    ? `${slide.props.className} glide__slide`
                    : 'glide__slide'
                })}
              </li>
            )
          })}
        </ul>
      </div>
      {hasDots && (
        <div className="glide__bullets" data-glide-el="controls[nav]">
          {React.Children.map(children, (item, index) => {
            if (index % options.perView === 0) {
              return (
                <div
                  key={index}
                  className={
                    currentIndex === index
                      ? 'glide__bullet glide__bullet__active'
                      : 'glide__bullet glide__bullet__inactive'
                  }
                  data-glide-dir={`=${index}`}
                ></div>
              )
            }
          })}
        </div>
      )}
      {hasArrows && (
        <div className="glide__arrows" data-glide-el="controls">
          <button
            className="glide__arrow glide__arrow--left"
            data-glide-dir="<"
          ></button>
          <button
            className="glide__arrow glide__arrow--right"
            data-glide-dir=">"
          ></button>
        </div>
      )}
    </Wrapper>
  )
}

Carousel.propTypes = {
  element: PropTypes.string,
  options: PropTypes.object,
  hasDots: PropTypes.bool,
  hasArrows: PropTypes.bool,
  centerBullets: PropTypes.bool
}

Carousel.defaultProps = {
  element: 'glide',
  options: { type: 'carousel', perView: 1 },
  hasDots: false,
  hasArrows: false,
  centerBullets: false
}

export default Carousel
