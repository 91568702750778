import { useState, useEffect } from 'react'

// Api
import Starlight from 'Starlight'

// Components
import Banner from 'components/Banner'
import Layout from 'components/Layout'
import Contact from 'components/Contact'
import Projects from 'components/Projects'
import SectionHeader from 'components/SectionHeader'
import SeeMore from 'components/SeeMore'


const Index = ({ home, footer, header }) => {
  const { title, desc, button, dark } = home?.data?.data
  const [contact, setContact] = useState(null)
  const [projects, setProjects] = useState(null)

  const fetchData = async () => {
    const [ contactData, projectsData ] = await Promise.all([
      Starlight.singletons.get('contact'),
      Starlight.entries.list('projetos', {l: '3', o: 'published_at:asc'}),  
    ])

    const data = {
      contactData: contactData || {}, 
      projectsData: projectsData || [], 
    }

    setContact(data.contactData)
    setProjects(data.projectsData)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Layout footer={footer} header={header} dark={dark}>
      <Banner projects={projects}/>

      <section>
        <SectionHeader isSectionTitle title={title} description={desc} dark={dark} />
      </section>

      <Projects projects={projects} dark={dark} />

      { projects?.data && button &&
        <SeeMore black={!dark}>{button}</SeeMore>
      }

      { contact?.data &&
        <Contact section contact={contact.data} dark={!dark} />
      }
    </Layout>
  )
}

export async function getServerSideProps() {
  const home = await Starlight.singletons.get('home')

  const data = {
    home: home || { }
  }

  return { 
    props: {
      ...data
    }
  }
}

export default Index
