import styled from '@emotion/styled'

// Library
import Container from 'library/Container'

const BannerSkeleton = styled.div`
  display: flex;
  align-items: flex-end;

  width: 100%;
  height: 75vw;
  max-height: 90vh;

  background: ${({ theme }) => theme.colors.primary};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.4);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: 100%;
  }
`

const ContentSkeleton = styled.div`
  width: 70vw;
  max-width: 870px;
  height: 2rem;

  margin: 0 auto 2rem;

  background: ${({ theme }) => theme.colors.primaryLight};

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin: 0 auto 4rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    height: 5.625vw;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: 4rem;
  }
`

const Title = styled.h3`
  font-size: 2rem;
  font-weight: 200;
  text-overflow: ellipsis;
  white-space: nowrap;

  overflow: hidden;
  opacity: 0;
  transform: translate(0, 100%);
  transition: all 0.8s ease-in-out;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 5.625vw;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    font-size: 4rem;
  }
`

const Category = styled.p`
  margin-top: 0.5rem;

  letter-spacing: 0.64px;
  text-transform: uppercase;
  font-size: 0.875rem;

  opacity: 0;
  transform: translate(0, 100%);
  transition: all 0.8s ease-in-out 0.8s;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    letter-spacing: 0.82px;
    font-size: 1rem;
    margin-top: 0.4444444444444444rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    font-size: 1rem;
  }
`

const Wrapper = styled.section`
  max-height: 90vh;
  margin-bottom: 1.375rem;

  img {
    height: 75vw;
    max-height: 90vh;
    width: 100%;

    object-fit: cover;

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
      height: 90vh;
    }
  }

  .glide__slides {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  .glide__slide {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;

      width: 100%;

      mix-blend-mode: multiply;

      @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        top: 50%;
      }
    }
  }

  .glide__slide--active ${Title}, .glide__slide--active ${Category} {
    opacity: 1;
    transform: translate(0);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-bottom: 3.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: 90vh;
  }
`

const Info = styled.div`
  display: flex;
  align-items: flex-end;

  position: absolute;
  bottom: 0;
  top: 0;
  z-index: 1;

  width: 100vw;

  ${Container} {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 5vw;

    > span {
      display: none;

      @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        display: block;
      }
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      justify-content: space-between;

      margin-bottom: 5.625vw;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
      margin-bottom: 4rem;
    }
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: column-reverse;
  max-width: 100%;

  text-align: center;
  color: #fff;

  overflow: hidden;
  z-index: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;

    text-align: left;
  }
`

export {
  BannerSkeleton,
  ContentSkeleton,
  Wrapper,
  Info,
  Details,
  Title,
  Category
}
