import Link from 'next/link'

// Library
import Container from 'library/Container'
import LinkArrow from 'library/Icons/LinkArrow'

// Components
import Carousel from 'components/Carousel'
import ResponsiveImage from 'components/ResponsiveImage'

// Styles
import {
  BannerSkeleton,
  ContentSkeleton,
  Wrapper,
  Info,
  Details,
  Title,
  Category
} from './style'

const Banner = ({ projects }) => {
  const bannerProjects = projects?.data.filter(project => project.data.is_slide)

  return (
    <Wrapper>
      {bannerProjects?.length > 0 ? (
        <Carousel options={{ autoplay: 6000, perView: 1 }} hasDots>
          {bannerProjects.map((item, index) => (
            <div key={index}>
              <Link href={`/projetos/${item.slug}`} passHref>
                <a title={item.title}>
                  <ResponsiveImage
                    image={item.data.card_image}
                    alt={item.title}
                  />
                  <Info>
                    <Container>
                      <Details>
                        <Title>{item.title}</Title>
                        {item?.category?.title && (
                          <Category>Arquitetura</Category>
                        )}
                      </Details>
                      <span>
                        <LinkArrow width="52" height="15" dark />
                      </span>
                    </Container>
                  </Info>
                </a>
              </Link>
            </div>
          ))}
        </Carousel>
      ) : (
        <BannerSkeleton>
          <Container>
            <ContentSkeleton />
          </Container>
        </BannerSkeleton>
      )}
    </Wrapper>
  )
}

export default Banner
